import React, {FunctionComponent} from 'react';
import styled from 'styled-components';
import Img, {GatsbyImageFluidProps, GatsbyImageProps} from 'gatsby-image';
import PageWidth from './PageWidth';
import Colours from '../constants/colours';
import {Button, ButtonVariant} from './Button';
import Typography from '../constants/typography';

interface HeroProps {
  data: {
    heroImage: GatsbyImageFluidProps;
    heroTitle: string;
    heroSubtitle: string;
    heroActionUrl: string;
    heroActionText: string;
  };
}

const Hero: FunctionComponent<HeroProps> = ({data}) => (
  <PageWidth wide>
    <HeroSection>
      <HeroImage
        fluid={data.heroImage.fluid}
        alt={data.heroTitle}
        data-cy="hero-image"
      />
      <LogoWrapper>
        <Title>{data.heroTitle}</Title>
        <Description>{data.heroSubtitle}</Description>
        <LearnMore
          variant={ButtonVariant.BLACK_OUTLINE_WHITE_FILL}
          onClick={() => (window.location.href = data.heroActionUrl)}
        >
          {data.heroActionText}
        </LearnMore>
      </LogoWrapper>
    </HeroSection>
  </PageWidth>
);

const HeroSection = styled.section`
  position: relative;
  margin-bottom: 3rem;

  @media (max-width: 600px) {
    height: 400px;
  }
`;

const HeroImage = styled(Img)<GatsbyImageProps>`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Title = styled.h1`
  font-size: ${Typography.featureTitle.desktop.fontSize};
  font-weight: bold;
  color: ${Colours.white};
`;

const Description = styled.p`
  color: ${Colours.white};
  font-size: ${Typography.subtitle.desktop.fontSize};
`;

const LearnMore = styled(Button)`
  margin-top: 2em;
  border: none;
`;

export default Hero;
