import React from 'react';
import {graphql, PageProps} from 'gatsby';
import {Layout} from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import {getPageData} from '../utils';
import {IndexQuery} from './__generated__/IndexQuery';

const IndexPage = (props: PageProps<IndexQuery>) => {
  const data = getPageData(props.data);

  return (
    <Layout>
      <SEO
        title={data.pageTitle}
        keywords={data.metaKeywords.split(',')}
        description={data.metaDescription}
      />
      <Hero data={data} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query IndexQuery {
    en: allContentfulAmpifyWebHome(filter: {node_locale: {eq: "en-GB"}}) {
      edges {
        node {
          pageTitle
          metaDescription
          metaKeywords
          heroTitle
          heroSubtitle
          heroActionText
          heroActionUrl
          heroImage {
            title
            fluid(maxWidth: 1400, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
